import { render, staticRenderFns } from "./DashboardObjetivos.vue?vue&type=template&id=0a1ba960&scoped=true&"
import script from "./DashboardObjetivos.vue?vue&type=script&lang=js&"
export * from "./DashboardObjetivos.vue?vue&type=script&lang=js&"
import style0 from "./DashboardObjetivos.vue?vue&type=style&index=0&id=0a1ba960&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a1ba960",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAutocomplete,VBtn,VCard,VCardActions,VCardText,VCardTitle,VCol,VDialog,VDivider,VForm,VIcon,VProgressLinear,VRow,VSimpleTable,VSkeletonLoader,VSpacer})
