<template>
	<v-row>
		<v-dialog v-model="dialog.status" scrollable>
			<v-card :min-width="dialog.grafico ? '650' : '2000'">
				<v-card-title class="primary--text font-weight-bold">
					<span v-if="dialog.dado">{{dialog.dado.titulo}}</span>
					<v-spacer />
					<v-btn color="primary" small fab text @click="dialog.status = false">
						<v-icon size="35">mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-divider />
				<v-card-text class="pa-4" v-if="meses && dialog.dado">
					<v-card elevation="0" class="rounded-0" style="border: 2px solid #023449;">
						<v-card-title class="primary white--text">Comparativo meses</v-card-title>
						<v-divider />
						<GraficoBarDashboard
							v-if="dialog.status"
							:dataSeries="[
							{
								name: 'Mês',
								data: dialog.dado.meses.map(v => v.valor.includes('-') ? v.valor.replaceAll('-', '') *-1: v.valor )
							},                            
						]"
							:categories="meses"
							:horizontal="false"
							:money="dialog.dado.tipo == 'money' ? true : false"
							:porcentagem="dialog.dado.tipo == 'porcentagem' ? true : false"
							height="250"
							:distributed="true"
							:dataLabels="{ position : 'bottom' , orientation : 'vertical', offsetY: 20 }"
						/>
					</v-card>
					<v-simple-table dense dark class="primary rounded-0">
						<thead>
							<tr>
								<th class="text-body-1 white--text">Mês</th>
								<th class="text-body-1 white--text">{{dialog.dado.calculo}}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(u,i)  in dialog.dado.meses" :key="i">
								<td>{{meses[i]}}</td>
								<td>
									{{u.calculo}}
									<strong class="pl-1" v-if="dialog.dado.tipo == 'money'">
										<template v-if="u.valor.includes('-')">- {{u.valor.replaceAll("-", "") | formataDinheiro}}</template>
										<template v-else>{{u.valor | formataDinheiro}}</template>
									</strong>
									<strong
										class="pl-1"
										v-else-if="dialog.dado.tipo == 'porcentagem'"
									>{{parseFloat(u.valor).toFixed(2)}} %</strong>
									<strong class="pl-1" v-else>{{u.valor}}</strong>
								</td>
							</tr>
						</tbody>
					</v-simple-table>
				</v-card-text>
				<v-card-actions class="pa-4">
					<v-btn color="primary" elevation="0" block @click="dialog.status = false">Fechar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-col cols="12">
			<v-card>
				<v-card-text>
					<v-form>
						<v-row class="mb-n10">
							<v-col class="pr-1">
								<InputDatePickerYear
									:disabled="carregando"
									v-model="busca.data"
									label="Data"
									:outlined="true"
									:dense="true"
								/>
							</v-col>
							<v-col class="px-1">
								<v-autocomplete
									:items="regionais"
									clearable
									item-text="regional"
									item-value="idregional"
									label="Regional"
									outlined
									dense
									:disabled="carregando"
									v-model="busca.idregional"
									@change="listarEmpresas()"
								></v-autocomplete>
							</v-col>
							<v-col class="px-1">
								<v-autocomplete
									:items="filiais.lista"
									clearable
									item-text="filial"
									item-value="idfilial"
									label="Filial"
									outlined
									dense
									:disabled="carregando"
									v-model="busca.idfiliais"
									multiple
								>
									<template v-slot:selection="{ item, index }">
										<template v-if="index === 0">{{ item.filial }}</template>
										<span
											v-if="index === 1"
											class="grey--text text-caption ml-1"
										>(+{{ busca.idfiliais.length - 1 }})</span>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col cols="auto" class="pl-1">
								<v-btn
									:disabled="carregando"
									class="ml-2 mt-1"
									color="primary"
									elevation="0"
									fab
									x-small
									@click="get()"
								>
									<v-icon>mdi-magnify</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12">
			<v-card dark color="primary">
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-title class="text-truncate">Objetivos {{busca.data}}</v-card-title>
				<v-card-text v-if="carregando">
					<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
				</v-card-text>
				<v-card-text v-else>
					<v-simple-table v-if="dados.meses" light dense class="rounded-0">
						<thead>
							<tr class="grey lighten-1">
								<th class="border-col text-body-2">Indicador</th>
								<th class="border-col text-body-2 text-center">Meta 2022</th>
								<th v-for="(mes, i) in meses" :key="i" class="border-col text-body-2 text-center">{{mes}}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="dado,i in tabela" :key="i" :class="dado.tr" @dblclick="abrirDialog(dado)">
								<td class="border-col" :class="dado.td">
									<v-icon @click="abrirDialog(dado)" color="primary" size="18">mdi-magnify-plus-outline</v-icon>
									{{dado.titulo}}
								</td>
								<td class="border-col text-right" :class="dado.td">{{dado.ano}}</td>
								<td
									class="border-col text-right"
									:class="dado.td"
									v-for="(mes, i) in dado.meses"
									:key="i"
								>{{mes.valor}}</td>
							</tr>
						</tbody>
					</v-simple-table>
					<span v-else>Nenhum registro encontrado</span>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import InputDatePickerYear from "../Widgets/InputDatePickerYear";
import GraficoBarDashboard from "../Widgets/GraficoBarDashboard";

export default {
	name: "DashboardObjetivos",
	components: {
		InputDatePickerYear,
		GraficoBarDashboard,
	},
	data: () => ({
		dialog: { status: false, grafico: false, dado: {} },
		carregando: false,
		busca: {
			data: `${new Date().getFullYear()}`,
			idregional: "",
			idfiliais: [],
		},
		dados: {},
		filiais: {},
		regionais: [],
	}),
	computed: {
		...mapState(["backendUrl"]),
		tabela() {
			let tabela = [];
			if (this.dados.meses) {
				tabela[0] = {
					titulo: "Crescimento do faturamento Mês/Ano anterior",
					calculo:
						"Venda do mês atual / venda do mês do ano anterior",
					td: "text-truncate",
					tr: "",
					ano: `${this.dados.meta.crescfaturamentoanoant.toFixed(
						2
					)} %`,
					meses: [],
				};
				tabela[0].meses = this.dados.meses.map((v) => {
					return {
						valor: `${v.crescfaturamentoanoant.toFixed(2)} %`,
						calculo: `((${parseFloat(v.crescimento).toLocaleString(
							"pt-br",
							{
								style: "currency",
								currency: "BRL",
							}
						)} / ${parseFloat(v.crescimentoanoant).toLocaleString(
							"pt-br",
							{
								style: "currency",
								currency: "BRL",
							}
						)}) - 1) * 100  =`,
					};
				});
				tabela[1] = {
					titulo: "Crescimento do faturamento Mês/Mês anterior",
					calculo: "Venda do mês atual / venda do mês anterior",
					td: "text-truncate",
					tr: "",
					ano: `${this.dados.meta.crescfaturamentomesant.toFixed(
						2
					)} %`,
					meses: [],
				};
				tabela[1].meses = this.dados.meses.map((v) => {
					return {
						valor: `${v.crescfaturamentomesant.toFixed(2)} %`,
						calculo: `((${parseFloat(v.crescimento).toLocaleString(
							"pt-br",
							{
								style: "currency",
								currency: "BRL",
							}
						)} / ${parseFloat(v.crescimentomesant).toLocaleString(
							"pt-br",
							{
								style: "currency",
								currency: "BRL",
							}
						)}) - 1) * 100 =`,
					};
				});
				tabela[2] = {
					titulo: "Crescimento do faturamento acumulado",
					calculo:
						"Percentual de crescimento acumulado / quantidade de meses",
					td: "text-truncate",
					tr: "",
					ano: `${this.dados.meta.crescfaturamentoacumulado.toFixed(
						2
					)} %`,
					meses: [],
				};
				tabela[2].meses = this.dados.meses.map((v) => {
					return {
						valor: `${v.crescfaturamentoacumulado.toFixed(2)} %`,
						calculo: `${v.crescimentoacumulado.toFixed(2)} % / ${
							v.mes
						} ${v.mes == 1 ? "mês" : "meses"} =`,
					};
				});
				tabela[3] = {
					titulo: "Faturamento (Milhões R$)",
					calculo: "Venda do mês atual",
					td: "text-truncate",
					tr: "",
					ano: this.dados.meta.faturamento.toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					}),
					meses: [],
				};
				tabela[3].meses = this.dados.meses.map((v) => {
					return {
						valor: parseFloat(v.faturamento).toLocaleString(
							"pt-br",
							{
								style: "currency",
								currency: "BRL",
							}
						),
						calculo: ``,
					};
				});
				tabela[4] = {
					titulo: "Margem do mês",
					calculo: "Lucro / venda do mês",
					td: "text-truncate",
					tr: "",
					ano: `${this.dados.meta.margemmes.toFixed(2)} %`,
					meses: [],
				};
				tabela[4].meses = this.dados.meses.map((v) => {
					return {
						valor: `${v.margemmes.toFixed(2)} %`,
						calculo: `${parseFloat(v.lucro).toLocaleString(
							"pt-br",
							{
								style: "currency",
								currency: "BRL",
							}
						)} / ${parseFloat(v.crescimento).toLocaleString(
							"pt-br",
							{
								style: "currency",
								currency: "BRL",
							}
						)} * 100 =`,
					};
				});
				tabela[5] = {
					titulo: "Margem média anual acumulada",
					calculo:
						"Percentual de margem média acumulada / quantidade de meses",
					td: "text-truncate",
					tr: "",
					ano: `${this.dados.meta.margemmediaanualacumulada.toFixed(
						2
					)} %`,
					meses: [],
				};
				tabela[5].meses = this.dados.meses.map((v) => {
					return {
						valor: `${v.margemmediaanualacumulada.toFixed(2)} %`,
						calculo: `${v.margemmediaacumulada.toFixed(2)} % / ${
							v.mes
						} ${v.mes == 1 ? "mês" : "meses"} =`,
					};
				});
				tabela[6] = {
					titulo: "*Margem DRE",
					calculo: "####",
					td: "text-truncate",
					tr: "",
					ano: `${this.dados.meta.margemdre.toFixed(2)} %`,
					meses: [],
				};
				tabela[6].meses = this.dados.meses.map((v) => {
					return {
						valor: `${v.margemdre.toFixed(2)} %`,
						calculo: `##`,
					};
				});
				tabela[7] = {
					titulo: "*Margem média DRE acumulada",
					calculo: "####",
					td: "text-truncate",
					tr: "",
					ano: `${this.dados.meta.margemmediadreacumulada.toFixed(
						2
					)} %`,
					meses: [],
				};
				tabela[7].meses = this.dados.meses.map((v) => {
					return {
						valor: `${v.margemmediadreacumulada.toFixed(2)} %`,
						calculo: `##`,
					};
				});
				tabela[8] = {
					titulo: "Despesa máxima s/ vendas",
					calculo: "Despesa operacional / receita operacional bruta",
					td: "text-truncate",
					tr: "",
					ano: `${this.dados.meta.despesamaximasvendas.toFixed(2)} %`,
					meses: [],
				};
				tabela[8].meses = this.dados.meses.map((v) => {
					return {
						valor: `${v.despesamaximasvendas.toFixed(2)} %`,
						calculo: `${parseFloat(v.despesa).toLocaleString(
							"pt-br",
							{
								style: "currency",
								currency: "BRL",
							}
						)} / ${parseFloat(v.receita).toLocaleString("pt-br", {
							style: "currency",
							currency: "BRL",
						})} * 100 =`,
					};
				});
				tabela[9] = {
					titulo: "Índice de contas vencidas s/ total caixa à prazo",
					calculo:
						"Caixa à prazo a receber vencido / caixa à prazo a receber",
					td: "text-truncate",
					tr: "",
					ano: `${this.dados.meta.indicecontavencida.toFixed(2)} %`,
					meses: [],
				};
				tabela[9].meses = this.dados.meses.map((v) => {
					return {
						valor: `${v.indicecontavencida.toFixed(2)} %`,
						calculo: `${parseFloat(
							v.saldocontavencida
						).toLocaleString("pt-br", {
							style: "currency",
							currency: "BRL",
						})} / ${parseFloat(v.totalcaixaprazo).toLocaleString(
							"pt-br",
							{
								style: "currency",
								currency: "BRL",
							}
						)} * 100 =`,
					};
				});
				tabela[10] = {
					titulo: "Aumento do caixa à prazo c/ preço à prazo",
					calculo:
						"Caixa à prazo a receber / caixa à prazo mês anterior",
					td: "text-truncate",
					tr: "",
					ano: `${this.dados.meta.aumentocaixaprazo.toFixed(2)} %`,
					meses: [],
				};
				tabela[10].meses = this.dados.meses.map((v) => {
					return {
						valor: `${v.aumentocaixaprazo.toFixed(2)} %`,
						calculo: `((${parseFloat(
							v.totalcaixaprazo
						).toLocaleString("pt-br", {
							style: "currency",
							currency: "BRL",
						})} / ${parseFloat(
							v.totalcaixaprazoanterior
						).toLocaleString("pt-br", {
							style: "currency",
							currency: "BRL",
						})}) - 1) * 100 =`,
					};
				});
				tabela[11] = {
					titulo: "*Check list regional",
					calculo: "####",
					td: "text-truncate",
					tr: "",
					ano: `${this.dados.meta.checklistregional.toFixed(2)} %`,
					meses: [],
				};
				tabela[11].meses = this.dados.meses.map((v) => {
					return {
						valor: `${v.checklistregional.toFixed(2)} %`,
						calculo: `##`,
					};
				});
				tabela[12] = {
					titulo: "Cobertura máxima de estoque (dias de venda)",
					calculo: "Custo de venda / estoque atual * 30 dias",
					td: "text-truncate",
					tr: "",
					ano: `${this.dados.meta.coberturamaximaestoque} dias`,
					meses: [],
				};
				tabela[12].meses = this.dados.meses.map((v) => {
					return {
						valor: `${v.coberturamaximaestoque.toFixed(0)} dias`,
						calculo: `${parseFloat(
							v.totalvendacusto
						).toLocaleString("pt-br", {
							style: "currency",
							currency: "BRL",
						})} / ${parseFloat(v.estoqueatual).toLocaleString(
							"pt-br",
							{
								style: "currency",
								currency: "BRL",
							}
						)} * 30 =`,
					};
				});
				tabela[13] = {
					titulo: "Divergência de estoques (falta)",
					calculo:
						"Sobra de estoque - perda de estoque / estoque atual",
					td: "text-truncate",
					tr: "",
					ano: `${this.dados.meta.divergenciaestoque.toFixed(2)} %`,
					meses: [],
				};
				tabela[13].meses = this.dados.meses.map((v) => {
					return {
						valor: `${v.divergenciaestoque.toFixed(2)} %`,
						calculo: `(${parseFloat(v.sobra).toLocaleString(
							"pt-br",
							{
								style: "currency",
								currency: "BRL",
							}
						)} - ${parseFloat(v.perda).toLocaleString("pt-br", {
							style: "currency",
							currency: "BRL",
						})}) / ${parseFloat(v.estoqueatual).toLocaleString(
							"pt-br",
							{
								style: "currency",
								currency: "BRL",
							}
						)} * 100 =`,
					};
				});
				tabela[14] = {
					titulo: "*Turnover máximo mensal RH",
					calculo:
						"Colaboradores admitidos + demitidos / 2 / total de colaboradores",
					td: "text-truncate",
					tr: "",
					ano: `${this.dados.meta.turnovermaximamensal.toFixed(2)} %`,
					meses: [],
				};
				tabela[14].meses = this.dados.meses.map((v) => {
					return {
						valor: `${v.turnovermaximamensal.toFixed(2)} %`,
						calculo: `##`,
					};
				});
				tabela[15] = {
					titulo: "Lucratividade",
					calculo: "Lucro ou prejuízo líquido do exercício DRE",
					td: "text-truncate",
					tr: "",
					ano: this.dados.meta.lucratividade.toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					}),
					meses: [],
				};
				tabela[15].meses = this.dados.meses.map((v) => {
					return {
						valor: `${parseFloat(v.lucratividade).toLocaleString(
							"pt-br",
							{
								style: "currency",
								currency: "BRL",
							}
						)}`,
						calculo: ``,
					};
				});
				tabela[16] = {
					titulo: "% entregas em 24h",
					calculo: "Entregas em 24h / total de entregas",
					td: "text-truncate",
					tr: "",
					ano: `${this.dados.meta.perentregas.toFixed(2)} %`,
					meses: [],
				};
				tabela[16].meses = this.dados.meses.map((v) => {
					return {
						valor: `${v.perentregas.toFixed(2)} %`,
						calculo: `${v.entr_prazo} / ${v.entr_soma_prazo} * 100 =`,
					};
				});
				tabela[17] = {
					titulo: "Meta para venda solar",
					calculo: "Venda de solar no mês atual",
					td: "text-truncate",
					tr: "",
					ano: this.dados.meta.metavendasolar.toLocaleString(
						"pt-br",
						{
							style: "currency",
							currency: "BRL",
						}
					),
					meses: [],
				};
				tabela[17].meses = this.dados.meses.map((v) => {
					return {
						valor: parseFloat(v.metavendasolar).toLocaleString(
							"pt-br",
							{
								style: "currency",
								currency: "BRL",
							}
						),
						calculo: ``,
					};
				});
				tabela[18] = {
					titulo: "*Quantidade de tickets TT",
					calculo: "####",
					td: "text-truncate",
					tr: "",
					ano: this.dados.meta.quantidadetickettt,
					meses: [],
				};
				tabela[18].meses = this.dados.meses.map((v) => {
					return {
						valor: `${v.totaltickets}`,
						calculo: ``,
					};
				});
				tabela[19] = {
					titulo: "Ticket médio",
					calculo: "Venda do mês atual / total de vendas (tickets)",
					td: "text-truncate",
					tr: "",
					ano: this.dados.meta.ticketmedio.toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					}),
					meses: [],
				};
				tabela[19].meses = this.dados.meses.map((v) => {
					return {
						valor: parseFloat(v.ticketmedio).toLocaleString(
							"pt-br",
							{
								style: "currency",
								currency: "BRL",
							}
						),
						calculo: `${parseFloat(v.crescimento).toLocaleString(
							"pt-br",
							{
								style: "currency",
								currency: "BRL",
							}
						)} / ${v.totaltickets} =`,
					};
				});
				tabela[20] = {
					titulo: "*% venda com cartão da empresa",
					calculo: "####",
					td: "text-truncate",
					tr: "",
					ano: `${this.dados.meta.vendacartaoempresa.toFixed(2)} %`,
					meses: [],
				};
				tabela[20].meses = this.dados.meses.map((v) => {
					return {
						valor: `${v.vendacartaoempresa.toFixed(2)} %`,
						calculo: `##`,
					};
				});
				tabela[21] = {
					titulo: "Faturamento e-commerce",
					calculo: "Venda do e-commerce mês atual",
					td: "text-truncate",
					tr: "",
					ano: this.dados.meta.faturamentoecommerce.toLocaleString(
						"pt-br",
						{
							style: "currency",
							currency: "BRL",
						}
					),
					meses: [],
				};
				tabela[21].meses = this.dados.meses.map((v) => {
					return {
						valor: parseFloat(
							v.faturamentoecommerce
						).toLocaleString("pt-br", {
							style: "currency",
							currency: "BRL",
						}),
						calculo: ``,
					};
				});
				tabela[22] = {
					titulo: "*Televendas",
					calculo: "####",
					td: "text-truncate",
					tr: "",
					ano: this.dados.meta.televendas.toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					}),
					meses: [],
				};
				tabela[22].meses = this.dados.meses.map((v) => {
					return {
						valor: parseFloat(v.televendas).toLocaleString(
							"pt-br",
							{
								style: "currency",
								currency: "BRL",
							}
						),
						calculo: `##`,
					};
				});
				tabela[23] = {
					titulo: "*Locação",
					calculo: "####",
					td: "text-truncate",
					tr: "",
					ano: this.dados.meta.locacao.toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					}),
					meses: [],
				};
				tabela[23].meses = this.dados.meses.map((v) => {
					return {
						valor: parseFloat(v.locacao).toLocaleString("pt-br", {
							style: "currency",
							currency: "BRL",
						}),
						calculo: `##`,
					};
				});
				tabela[24] = {
					titulo: "*Vendas externas",
					calculo: "####",
					td: "text-truncate",
					tr: "",
					ano: this.dados.meta.vendaexterna.toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					}),
					meses: [],
				};
				tabela[24].meses = this.dados.meses.map((v) => {
					return {
						valor: parseFloat(v.vendaexterna).toLocaleString(
							"pt-br",
							{
								style: "currency",
								currency: "BRL",
							}
						),
						calculo: `##`,
					};
				});
				tabela[25] = {
					titulo: "*Redução encalhado",
					calculo: "####",
					td: "text-truncate",
					tr: "",
					ano: this.dados.meta.reducaoencalhado.toLocaleString(
						"pt-br",
						{
							style: "currency",
							currency: "BRL",
						}
					),
					meses: [],
				};
				tabela[25].meses = this.dados.meses.map((v) => {
					return {
						valor: parseFloat(v.reducaoencalhado).toLocaleString(
							"pt-br",
							{
								style: "currency",
								currency: "BRL",
							}
						),
						calculo: `##`,
					};
				});
				tabela[26] = {
					titulo: "*Redução excesso",
					calculo: "####",
					td: "text-truncate",
					tr: "",
					ano: this.dados.meta.reducaoexcesso.toLocaleString(
						"pt-br",
						{
							style: "currency",
							currency: "BRL",
						}
					),
					meses: [],
				};
				tabela[26].meses = this.dados.meses.map((v) => {
					return {
						valor: parseFloat(v.reducaoexcesso).toLocaleString(
							"pt-br",
							{
								style: "currency",
								currency: "BRL",
							}
						),
						calculo: `##`,
					};
				});
				tabela[27] = {
					titulo: "Venda por colaborador",
					calculo:
						"Venda do mês atual / total de colaboradores ativos",
					td: "text-truncate",
					tr: "",
					ano: this.dados.meta.vendaporcolaborador.toLocaleString(
						"pt-br",
						{
							style: "currency",
							currency: "BRL",
						}
					),
					meses: [],
				};
				tabela[27].meses = this.dados.meses.map((v) => {
					return {
						valor: parseFloat(v.vendaporcolaborador).toLocaleString(
							"pt-br",
							{
								style: "currency",
								currency: "BRL",
							}
						),
						calculo: `${parseFloat(v.crescimento).toLocaleString(
							"pt-br",
							{
								style: "currency",
								currency: "BRL",
							}
						)} / ${v.turnoverativos} =`,
					};
				});
				tabela[28] = {
					titulo: "Venda por metro quadrado",
					calculo: "Venda do mês atual / metro quadrado de loja",
					td: "text-truncate",
					tr: "",
					ano: this.dados.meta.vendapormetroquadrado.toLocaleString(
						"pt-br",
						{
							style: "currency",
							currency: "BRL",
						}
					),
					meses: [],
				};
				tabela[28].meses = this.dados.meses.map((v) => {
					return {
						valor: parseFloat(
							v.vendapormetroquadrado
						).toLocaleString("pt-br", {
							style: "currency",
							currency: "BRL",
						}),
						calculo: `${parseFloat(v.crescimento).toLocaleString(
							"pt-br",
							{
								style: "currency",
								currency: "BRL",
							}
						)} / ${v.metroquadrado} =`,
					};
				});
			}
			return tabela;
		},
		meses() {
			let meses = [];
			if (this.dados.meses) {
				meses = this.dados.meses.map((v) =>
					v.mes < 10
						? `0${v.mes}/${this.busca.data}`
						: `${v.mes}/${this.busca.data}`
				);
			}
			return meses;
		},
	},
	methods: {
		listarEmpresas() {
			this.busca.idfiliais = [];
			return axios
				.post(`${this.backendUrl}filial/listar`, {
					limit: 9999,
					offset: 0,
					idregional: this.busca.idregional || null,
					busca: null,
				})
				.then((res) => {
					this.filiais = res.data;
					this.filiais.lista = this.filiais.lista.filter((v) => {
						return (
							v.idfilial !== 6 &&
							v.idfilial !== 12 &&
							v.idfilial !== 22 &&
							v.idfilial !== 25 &&
							v.idfilial !== 29 &&
							v.idfilial !== 32 &&
							v.idfilial !== 33 &&
							v.idfilial !== 34 &&
							v.idfilial !== 37
						);
					});
				});
		},
		listarRegionais() {
			return axios
				.post(`${this.backendUrl}filial/regional/listar`)
				.then((res) => {
					this.regionais = res.data;
				});
		},
		get() {
			let idfiliais = null;
			if (this.busca.idfiliais.length > 0) {
				idfiliais = this.busca.idfiliais.join(",");
			}
			this.dados = {};
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}dashboard/objetivos`, {
					data: this.busca.data || null,
					idregional: `${this.busca.idregional}` || null,
					filial: idfiliais || null,
				})
				.then((res) => {
					this.dados = res.data;
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		abrirDialog(dado) {
			let dados = JSON.parse(JSON.stringify(dado));
			this.dialog.status = true;
			if (!this.dialog.grafico) {
				setTimeout(() => (this.dialog.grafico = true), 10);
			}
			if (dados.meses) {
				let meses = [];
				dados.meses.forEach((v) => {
					if (v.valor.includes("%")) {
						dados.tipo = "porcentagem";
						meses.push({
							valor: v.valor.replaceAll(" %", ""),
							calculo: v.calculo,
						});
					} else if (v.valor.includes("R$")) {
						dados.tipo = "money";
						meses.push({
							valor: v.valor
								.replaceAll("R$", "")
								.replaceAll(".", "")
								.replaceAll(",", "."),
							calculo: v.calculo,
						});
					} else if (
						v.valor == undefined ||
						v.valor == null ||
						v.valor == ""
					) {
						meses.push({
							valor: `0`,
							calculo: v.calculo,
						});
					} else {
						meses.push({ valor: `${v.valor}`, calculo: v.calculo });
					}
				});
				this.dialog.dado = dados;
				this.dialog.dado.meses = meses;
			}
		},
		async init() {
			await this.listarEmpresas();
			await this.listarRegionais();
			await this.get();
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
.border-col {
	border: 1px solid #023449;
}
</style>
