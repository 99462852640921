<template>
	<v-menu :close-on-content-click="true" v-model="menu" max-width="290px">
		<template v-slot:activator="{ on }">
			<v-text-field
				:prefix="prefix"
				:persistent-hint="persistentHint"
				:flat="flat"
				:dense="dense"
				:disabled="disabled"
				:clearable="clearable"
				@click:clear="updateValue(null)"
				:hide-details="hideDetails"
				v-on="on"
				:outlined="outlined"
				:solo-inverted="soloInverted"
				:label="label"
				v-model="dataInternaPt"
				readonly
			/>
		</template>
		<v-date-picker
			reactive
			show-current
			ref="picker"
			v-model="dataInternaEn"
			:min="min"
			:max="max"
			no-title
			@input="updateValue"
		/>
	</v-menu>
</template>

<script>
export default {
	name: "InputDatePickerYear",
	props: [
		"label",
		"value",
		"flat",
		"hideDetails",
		"outlined",
		"soloInverted",
		"dense",
		"prefix",
		"persistentHint",
		"clearable",
		"disabled",
		"type",
	],
	data: () => ({
		menu: false,
		min: "1980",
		max: `${new Date().getFullYear() + 4}`,
		dataInternaEn: null,
		dataInternaPt: null,
	}),
	methods: {
		updateValue(v) {
			if (v == "") {
				v = null;
			}
			this.menu = false;
			this.$emit("input", v.split("-")[0]);
		},
	},
	watch: {
		value: function () {
			this.dataInternaEn = this.value;
			this.dataInternaPt = this.value.split("-")[0];
		},
		menu: function (val) {
			val &&
				this.$nextTick(() => (this.$refs.picker.activePicker = "YEAR"));
		},
	},
	created() {
		this.dataInternaEn = this.value;
		this.dataInternaPt = this.value.split("-")[0];
	},
};
</script>

<style scoped>
</style>

